<template>
	<div id="userList">
		<div class="el-content">
			<a-space>
				<a-input-search v-model:value="keyword" style="width: 300px" placeholder="姓名" enter-button @search="getUserList(1, info.limit)" />
				<a-button type="primary" v-has="{ action: 'sass_agency_add' }" @click="show.addOrEdit = true">
					<i class="ri-add-line ri-top"></i>
					添加用户
				</a-button>
			</a-space>
		</div>
		<div class="el-content">
			<a-table
				:pagination="false"
				row-key="user_id"
				:data-source="info.list"
				:columns="[
					{ title: 'ID', dataIndex: 'user_id' },
					{ title: '手机号', dataIndex: 'mobile' },
					{ title: '用户名', dataIndex: 'name' },
					{ title: '状态', dataIndex: 'status', slots: { customRender: 'status' } },
					{ title: '创建时间', dataIndex: 'create_time' },
					{ title: '最后登录时间', dataIndex: 'login_time' },
					{ title: '最后登录IP', dataIndex: 'last_login_ip' },
					{ title: '操作', dataIndex: 'action', slots: { customRender: 'action' } }
				]"
			>
				<template #status="{ record }">
					<a-tag :color="record.status == 1 ? '#00CC66' : '#FF6633'">{{ record.status ? '正常' : '已禁用' }}</a-tag>
				</template>
				<template #action="{ record }">
					<router-link :to="{ path: '/admin/user/user_info', query: { user_id: record.user_id } }">
						<a-button v-has="{ action: '/admin/user/user_info' }" type="link" size="small">详情</a-button>
					</router-link>
					<a-button v-has="{ action: 'sass_user_stop' }" type="link" @click="deletUser(record)" size="small">{{ record.status == 1 ? '禁用' : '启用' }}</a-button>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					v-model:current="info.page"
					v-model:pageSize="info.limit"
					:total="info.count"
					@showSizeChange=" (p, e) => { getUserList(info.page, e); } "
					@change="e => getUserList(e, info.limit)"
				/>
			</div>
		</div>
		<a-modal v-model:visible="show.addOrEdit" title="添加编辑用户" width="700px"  @ok="createOrUpdateAgent" @cancel="show.addOrEdit = false">
			<a-form :label-col="{span:4}" :wrapper-col="{span:18}">
				<a-form-item label="昵称">
					<a-input v-model:value="form.name"></a-input>
				</a-form-item>
				<a-form-item label="手机号">
					<a-input v-model:value="form.mobile"></a-input>
				</a-form-item>
				<a-form-item label="密码">
					<a-input v-model:value="form.password"></a-input>
				</a-form-item>
				<a-form-item label="地址">
					<a-input v-model:value="form.address"></a-input>
				</a-form-item>
				<a-form-item label="永久授权">
					<a-radio-group v-model:value="form.is_permanent">
						<a-radio :value="1">开启</a-radio>
						<a-radio :value="0">关闭</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="授权到期时间">
					<a-date-picker v-model:value="form.validity_time" format="YYYY-MM-DD"/>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import authModel from '@/api/saas/auth';
export default {
	setup(){
		const state = reactive({
			info: {
				list: [],
				page: 1,
				count: 0,
				limit: 10
			},
			keyword: '',
			show: {
				addOrEdit: false
			},
			form: {
				name: '',
				mobile: '',
				password: '',
				is_permanent: 0,
				validity_time: '',
				address: ''
			}
		})
		getUserList(1,state.info.limit)

		function getUserList(page,limit){
			authModel.getMerchantUserList(page,limit,{keyword:state.keyword},res=>state.info = {limit,...res})
		}

		const deletUser = row=>authModel.changeEmployeeStatus(row.id,row.status,()=>{
			getUserList(state.info.page,state.info.limit)
		})

		const createOrUpdateAgent =()=>authModel.addMerchantUser(JSON.parse(JSON.stringify(state.form)),()=>{
			getUserList(state.info.page,state.info.limit)
			state.show.addOrEdit = false;
			state.form.name = '';
			state.form.mobile = '';
			state.form.password = '';
			state.form.is_permanent = 0;
			state.form.validity_time = '';
			state.form.address = '';
		})

		return{
			...toRefs(state),
			getUserList,
			deletUser,
			createOrUpdateAgent
		}
	}
}
</script>

<style lang="scss">
</style>
